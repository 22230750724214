import {
  usePathname,
  useRouter,
  useSearchParams,
  useParams,
} from "next/navigation";
import { useContext } from "react";

import { companySlugContext } from "@/providers/company-slug";
import { getNewPath } from "@/utils/misc";

function getCookie(name: string) {
  const cookie: Record<string, string | undefined> = {};
  if (typeof window === "undefined") return undefined;
  document.cookie.split(";").forEach(function (el) {
    const split = el.split("=");
    const first = split[0]?.trim();
    if (!first) return;
    cookie[first] = split.slice(1).join("=");
  });
  return cookie[name];
}

export default function useCompany() {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { companySlug: companySlugFromParams } = useParams();
  const { companySlug, setCompanySlug } = useContext(companySlugContext);
  const companySlugFromCookie = getCookie("lh.current-company");
  const finalCompanySlug =
    (Array.isArray(companySlugFromParams)
      ? companySlugFromParams[0]
      : companySlugFromParams) ??
    companySlugFromCookie ??
    companySlug;

  const changeCompany = (newCompanySlug: string) => {
    if (finalCompanySlug && pathname.includes(finalCompanySlug)) {
      const newPath = pathname.replace(finalCompanySlug, newCompanySlug);
      router.push(
        getNewPath({
          destination: newPath,
          searchParams,
        }),
      );
      setCompanySlug(newCompanySlug);
    } else {
      router.push(`/${newCompanySlug}/dashboard/overview`);
    }
  };
  return [finalCompanySlug, changeCompany] as const;
}
