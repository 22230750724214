"use client";

import * as React from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import { NewCompanyForm } from "@/components/form/new-company";

type CompanyCreationDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCompanyCreate?: (companySlug: string) => void;
};

export const CompanyCreationDialog = ({
  open,
  onOpenChange,
  onCompanyCreate,
}: CompanyCreationDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create company</DialogTitle>
          <DialogDescription>My company details</DialogDescription>
        </DialogHeader>
        <div>
          <NewCompanyForm
            onCancel={() => {
              onOpenChange(false);
            }}
            onCompanyCreate={(companySlug) => {
              onOpenChange(false);
              onCompanyCreate?.(companySlug);
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
