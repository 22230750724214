"use client";

import { Suspense, useMemo } from "react";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Bell } from "lucide-react";
import { type RouterOutputs } from "@cloudifybiz/lighthouse-core/trpc/root";
import { getRelativeTime } from "@/utils/misc";
import { useRouter } from "next/navigation";
import { Separator } from "./ui/separator";

const NotificationTiles = ({
  notification,
}: {
  notification: RouterOutputs["user"]["notification"]["web"]["list"][number];
}) => {
  const router = useRouter();
  const timestamp = useMemo(
    () => getRelativeTime(notification.updatedAt),
    [notification.updatedAt],
  );

  return (
    <div key={notification.id}>
      <div
        onClick={() => {
          if (notification.link) router.push(notification.link);
        }}
        className="space-y-0.25 cursor-pointer items-center transition-opacity hover:opacity-50"
      >
        <div className="space-y-0.25">
          <p className="text-md font-bold">{notification.source}</p>
          <p className="text-sm font-semibold tracking-tight">
            {notification.text}
          </p>
        </div>
        <span className="text-xs">{timestamp}</span>
      </div>
      <Separator />
    </div>
  );
};

export function WebNotifications({
  notifications,
}: {
  notifications: RouterOutputs["user"]["notification"]["web"]["list"];
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="icon">
          <Bell className="h-[1.2rem] w-[1.2rem]" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col space-y-2">
        <div className="flex justify-between">
          <h1 className="mb-6 flex items-center gap-x-0.5 text-xl font-bold tracking-tight">
            {" "}
            <Bell className="h-[1.2rem] w-[1.2rem]" /> Notifications
          </h1>
        </div>
        {notifications.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <span>No notifications</span>
          </div>
        )}
        <Suspense>
          {notifications.map((notification) => (
            <NotificationTiles
              key={notification.id}
              notification={notification}
            />
          ))}
        </Suspense>
        {notifications.length > 5 && <Button variant="ghost"> See All </Button>}
      </PopoverContent>
    </Popover>
  );
}
